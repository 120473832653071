import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { DateTime } from 'luxon'

Font.register({
	family: 'Open Sans',
	fonts: [
		{ src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
			fontWeight: 600
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
			fontWeight: 700
		},
		{
			src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf',
			fontWeight: 800
		}
	]
})

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Open Sans',
		fontSize: 12,
		paddingVertical: 20,
		paddingHorizontal: 30
	},
	section: {
		marginBottom: 35
	},
	title: {
		fontSize: 20,
		fontWeight: 800,
		textDecoration: 'underline',
		textAlign: 'left'
	},
	right: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-end'
	},
	column: {
		display: 'flex',
		flexDirection: 'column'
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	evenRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingVertical: 5,
		paddingHorizontal: 2,
		backgroundColor: '#E5E7EB'
	},
	oddRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		paddingHorizontal: 2,
		paddingVertical: 5
	},
	text: {
		fontSize: 10
	}
})

interface InvoiceDocumentProps {
	invoices: Invoice[]
	auth: AuthState
	selectedRange: { from: string; to: string } | undefined
}

export const InvoiceDocument = ({ auth, invoices, selectedRange }: InvoiceDocumentProps) => {
	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<View style={styles.section}>
					<View style={{ ...styles.column, rowGap: 10 }}>
						<View style={{ ...styles.row, justifyContent: 'space-between' }}>
							<Text style={{ fontWeight: 'bold' }}>Fahrdienst-Einsatzleitung</Text>
							<View style={{ ...styles.row, alignItems: 'flex-start' }}>
								<View style={styles.column}>
									<Text style={{ fontWeight: 'bold' }}>Patienten-Fahrdienst</Text>
									<Text style={{ fontWeight: 'bold' }}>der Stadt Dübendorf</Text>
								</View>
								<Image
									src={window.location.origin + '/images/favicon.jpeg'}
									style={{ height: '40px', marginLeft: 10 }}
								/>
							</View>
						</View>
						<View style={styles.column}>
							<Text>{auth.fname + ' ' + auth.lname}</Text>
							<Text>{auth.address}</Text>
							{auth.zip && auth.city && <Text>{auth.zip + ' ' + auth.city}</Text>}
						</View>
					</View>
				</View>
				<View style={styles.section}>
					<View style={{ ...styles.right, marginRight: 120 }}>
						<Text style={{ fontSize: 11, marginBottom: 10 }}>
							Dübendorf - {DateTime.now().toFormat('dd.LL.yyyy')}
						</Text>
						<Text style={{ fontWeight: 'bold' }}>
							{invoices[0].id_client.fname + ' ' + invoices[0].id_client.lname}
						</Text>
						<Text style={{ fontWeight: 'bold' }}>{invoices[0].id_client.address}</Text>
						<Text style={{ fontWeight: 'bold' }}>
							{invoices[0].id_client.zipcode} {invoices[0].id_client.city}
						</Text>
					</View>
				</View>
				<View style={styles.section}>
					<View style={styles.column}>
						<Text style={styles.title}>Quittung für Patienten-Transporte</Text>
						<View style={styles.row}>
							<Text style={{ fontWeight: 'bold', marginVertical: 5 }}>Periode:</Text>
							<Text style={{ fontWeight: 'bold', marginLeft: 10 }}>
								{selectedRange
									? `${selectedRange.from} - ${selectedRange.to}`
									: DateTime.fromMillis(Number(invoices[0].id_booking.appointment_time)).toFormat(
											'dd. LLL. yy'
										)}
							</Text>
						</View>
						<View style={{ ...styles.column, marginTop: 10 }}>
							<View style={{ ...styles.row, marginBottom: 10 }}>
								<Text style={{ fontWeight: 'bold', width: '5%', fontSize: 10 }}>KW</Text>
								<Text style={{ fontWeight: 'bold', width: '10%', fontSize: 10 }}>Datum</Text>
								<Text style={{ fontWeight: 'bold', width: '20%', fontSize: 10 }}>Fahrt von</Text>
								<Text style={{ fontWeight: 'bold', width: '20%', fontSize: 10 }}>nach</Text>
								<Text style={{ fontWeight: 'bold', width: '30%', fontSize: 10 }}>Ziel</Text>
								<Text style={{ fontWeight: 'bold', width: '15%', fontSize: 10 }}>Kosten</Text>
							</View>
							{invoices.map((invoice, index) => (
								<View style={index % 2 === 0 ? styles.oddRow : styles.evenRow} key={invoice._id}>
									<Text style={{ width: '5%', fontSize: 8 }}>
										{invoice.id_booking.destination.distance}
									</Text>
									<Text style={{ width: '10%', fontSize: 8 }}>
										{DateTime.fromMillis(Number(invoice.id_booking.appointment_time)).toFormat(
											'dd. LLL. yy'
										)}
									</Text>
									<Text style={{ width: '20%', fontSize: 8 }}>
										{invoice.id_booking.pickup_address}
									</Text>
									<Text style={{ width: '20%', fontSize: 8 }}>
										{invoice.id_booking.return_address}
									</Text>
									<Text style={{ width: '30%', fontSize: 8 }}>
										{invoice.id_booking.destination.address}
									</Text>
									<Text style={{ width: '15%', fontSize: 8 }}>
										Fr. {invoice.id_booking.total_price.toFixed(2)}
									</Text>
								</View>
							))}
							<View style={{ ...styles.row, justifyContent: 'space-between', marginTop: 10 }}>
								<View style={{ ...styles.row, gap: 14, marginLeft: 100 }}>
									<Text style={{ fontWeight: 'bold', fontSize: 10 }}>Anzahl Fahrten</Text>
									<Text style={{ fontWeight: 'bold', fontSize: 10 }}>{invoices.length}</Text>
								</View>
								<View style={{ ...styles.row, gap: 34, marginRight: 50 }}>
									<Text style={{ fontWeight: 'bold', fontSize: 10 }}>Total:</Text>
									<Text style={{ fontWeight: 'bold', textDecoration: 'underline', fontSize: 10 }}>
										Fr.{' '}
										{invoices
											.reduce((agg, invoice) => agg + invoice.id_booking.total_price, 0)
											.toFixed(2)}
									</Text>
								</View>
							</View>
							<Text style={{ fontSize: 8, marginTop: 25 }}>
								Hiermit bestätige ich, dass die obengenannten Beträge an die Fahrer ausbezahlt
								wurden.
							</Text>
						</View>
					</View>
				</View>
				<Text style={{ fontSize: 8, marginTop: 30 }}>{auth.fname + ' ' + auth.lname}</Text>
			</Page>
		</Document>
	)
}
