import axios from 'services/axios'

const getCompanyDrivers = (): Promise<Driver[]> => {
	return axios.get('driver').then(response => response.data)
}

const getDriverById = (id: string): Promise<Driver> => {
	return axios.get(`/driver/${id}`).then(response => response.data)
}

const updateDriver = (id: string, data: DriverForm) => {
	return axios.put(`/driver/${id}`, data).then(response => response.data)
}

const getAvailableDrivers = (time: number) => {
	return axios.post('/available-drivers', { time }).then(response => response.data)
}

const createDriver = (data: DriverForm) => {
	return axios.post('/driver', data).then(response => {
		return response.data
	})
}

const driverService = {
	getCompanyDrivers,
	getAvailableDrivers,
	getDriverById,
	updateDriver,
	createDriver
}

export default driverService
