import { CheckmarkIcon, DeleteIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { AppLayout } from 'components/app/layout'
import { Modal } from 'components/app/modal'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getTKey } from 'utils/language'
import appointmentService from '../../services/appointment-service'

export const BookingStatus = () => {
	const { id } = useParams<{ id: string }>()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()

	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState('')

	const tKey = getTKey('appointments')

	const isConfirmationSuccess = searchParams.get('status') === 'confirmed'
	const driverId = searchParams.get('id_driver') as string

	useEffect(() => {
		if (id && searchParams.get('status')) {
			updateBookingStatus(id, searchParams.get('status') as string)
		}
	}, [id, searchParams])

	const updateBookingStatus = async (id: string, status: string) => {
		setIsLoading(true)
		try {
			const response = await appointmentService.updateAppointmentStatus(id, status, driverId)
			if (!response.message.includes('Successfully')) {
				setError(response.message)
			} else {
				setError('Successfully')
			}
		} catch (err) {
			setError((err as any)?.response?.data?.message)
		} finally {
			setIsLoading(false)
		}
	}

	if (isLoading) {
		return (
			<div className="flex flex-col gap-y-2 items-center justify-center h-screen">
				<Spinner />
				<span className="animate-pulse whitespace-nowrap">{t(tKey('labels.processing'))}</span>
			</div>
		)
	}

	if (!error.includes('Successfully')) {
		return (
			<Modal onClose={() => navigate('/bookings')} showCrossIcon={true} isFullHeight>
				<AppLayout
					isHeaderVisible={false}
					isSidebarVisible={false}
					title={t(tKey('titles.appointments'))}>
					<div className="flex flex-col py-[100px] px-[24px]">
						<img
							src={DeleteIcon}
							alt="Checkmark"
							className=" mx-auto h-14 w-14 mb-10 object-contain"
						/>

						<h1 className="text-primary font-domine text-2xl font-bold leading-8 capitalize text-center mb-3.5">
							{error || 'Something went wrong!'}
						</h1>
					</div>
				</AppLayout>
			</Modal>
		)
	}

	if (isConfirmationSuccess) {
		return (
			<Modal onClose={() => navigate('/bookings')} showCrossIcon={true} isFullHeight>
				<AppLayout
					isSidebarVisible={false}
					isHeaderVisible={false}
					title={t(tKey('titles.appointments'))}>
					<div className="flex flex-col py-[100px] px-[24px]">
						<img src={CheckmarkIcon} alt="Checkmark" className=" mx-auto mb-10 object-contain" />

						<h1 className="text-primary font-domine text-2xl font-bold leading-8 capitalize text-center mb-3.5">
							{t(tKey('labels.confirmed'))}
						</h1>
					</div>
				</AppLayout>
			</Modal>
		)
	}

	return (
		<Modal onClose={() => navigate('/bookings')} showCrossIcon={true} isFullHeight>
			<AppLayout
				isHeaderVisible={false}
				isSidebarVisible={false}
				title={t(tKey('titles.appointments'))}>
				<div className="flex flex-col py-[100px] px-[24px]">
					<img
						src={DeleteIcon}
						alt="Checkmark"
						className=" mx-auto h-14 w-14 mb-10 object-contain"
					/>

					<h1 className="text-primary font-domine text-2xl font-bold leading-8 capitalize text-center mb-3.5">
						{t(tKey('labels.cancelled'))}
					</h1>
					<p className="text-secondary leading-6 text-base font-nunito text-center font-normal">
						{t(tKey('labels.details'))}
					</p>
				</div>
			</AppLayout>
		</Modal>
	)
}
