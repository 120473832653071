import { useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import appointmentService from 'services/appointment-service'
import eventService from 'services/event-service'

interface SlotProps {
	day: DateTime
	hour: number
	onUpdate: () => void
}

const Slot = ({ day, hour, onUpdate }: SlotProps) => {
	const updateDatetime = useCallback(
		(event: any) => {
			const { id, startDateTime, endDateTime, totalMinutes, ...data } = event
			const from = day.set({ hour })

			const eventStart = DateTime.fromISO(startDateTime)
			const eventEnd = DateTime.fromISO(endDateTime)
			const startMinutes = eventStart.hour * 60 + eventStart.minute
			const endMinutes = eventEnd.hour * 60 + eventEnd.minute

			const to = from.plus({ minutes: endMinutes - startMinutes })

			if (event.type === 'appointment') {
				appointmentService
					.updateAppointmentSlots(id, from.toMillis(), to.toMillis())
					.then(res => {
						onUpdate()
					})
					.catch(err => toast.error(err?.response?.data?.message))
			} else {
				eventService
					.updateEvent(id, {
						title: data.title,
						description: data.description,
						duration: data.duration,
						guests: data.guests,
						allDay: data.allDay,
						colorCode: data.color,
						id_company: data.companyId,
						from: from.toMillis(),
						to: to.toMillis()
					})
					.then(() => {
						onUpdate()
					})
					.catch(err => toast.error(err?.response?.data?.message))
			}
		},
		[day, hour]
	)

	const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
		accept: 'weekly',
		drop: (item: any) => {
			updateDatetime(item)
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	}))

	return (
		<div
			ref={dropRef}
			className={clsx({
				'bg-gray-50':
					hour < 7 || hour > 19 || day.weekdayShort === 'Sat' || day.weekdayShort === 'Sun',
				'!bg-green-200': isOver && canDrop,
				'!bg-red-200': isOver && !canDrop
			})}
		/>
	)
}

interface AllSlotsProps {
	days: DateTime[]
	onUpdate: () => void
}

const AllSlots = ({ days, onUpdate }: AllSlotsProps) => {
	const hours = new Array(24).fill(0)

	return (
		<div className="col-start-1 col-end-8 row-start-1 grid grid-rows-[repeat(24,minmax(0,1fr))] grid-cols-7">
			{hours.map((_, hourIndex) =>
				days.map((day, dayIndex) => (
					<Slot day={day} hour={hourIndex} key={`${dayIndex} - ${hourIndex}`} onUpdate={onUpdate} />
				))
			)}
		</div>
	)
}

export default AllSlots
