import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DateTime } from 'luxon'

import { Modal } from 'components/app/modal'
import { CalendarEvent } from 'components/calendar'
import { EventDetail } from 'components/calendar/event-detail'
import DailySlot from 'components/calendar/month-calendar/daily-slot'
import { Language } from 'constants/constants'
import { useComponentVisible } from 'hooks/useComponentVisible'
import useIsMobile from 'hooks/useIsMobile'
import { getAppLang } from 'utils/language'

import '../scroll-bar.css'

interface ViewProps {
	currentDate: DateTime
	events: CalendarEvent[]
	onDateClick: (date: DateTime) => void
	refetch: () => void
	onAppointmentClick: (appointmentId: string) => void
	onEventClick: (eventId: string) => void
	onUpdate: () => void
}

const MonthCalendar = ({
	currentDate,
	events,
	refetch,
	onAppointmentClick,
	onDateClick,
	onEventClick,
	onUpdate
}: ViewProps) => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const [isDragging, setIsDragging] = useState(false)
	const [openNotePopup, setOpenNotePopup] = useState(false)
	const [openAppointmentPopup, setOpenAppointmentPopup] = useState(false)

	const startOfMonth = currentDate.startOf('month')
	const endOfMonth = currentDate.endOf('month')
	const startDate = startOfMonth.startOf('week')
	const endDate = endOfMonth.endOf('week')

	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
	const [selectedEvent, setSelectedEvent] = useState<CalendarEvent>()

	const days = []
	let day = startDate

	while (day <= endDate) {
		days.push(day)
		day = day.plus({ days: 1 })
	}

	const handleEventSelection = (event: CalendarEvent, date: string) => {
		setSelectedEvent(event)
		setIsComponentVisible(true)
	}

	const daysList = isMobile
		? ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']
		: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

	const germanDaysList = isMobile
		? ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So']
		: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag']

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div className="flex flex-col -mt-4 gap-5 w-full h-full">
			<div ref={ref}>
				{((isComponentVisible && selectedEvent && !isMobile) ||
					openNotePopup ||
					openAppointmentPopup) && (
					<EventDetail
						{...selectedEvent}
						openNotePopup={openNotePopup}
						setOpenNotePopup={(value: boolean) => {
							setOpenNotePopup(value)
							setIsComponentVisible(true)
						}}
						openAppointmentPopup={openAppointmentPopup}
						setOpenAppointmentPopup={(value: boolean) => {
							setOpenAppointmentPopup(value)
							setIsComponentVisible(true)
						}}
						refetch={() => {
							setSelectedEvent(undefined)
							setIsComponentVisible(false)
							refetch()
						}}
						onAppointmentClick={(id: string) => {
							setIsComponentVisible(false)
							onAppointmentClick(id)
						}}
						onEventClick={(id: string) => {
							setIsComponentVisible(false)
							onEventClick(id)
						}}
					/>
				)}
			</div>
			{selectedEvent && isMobile && (
				<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setSelectedEvent(undefined)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary text-[20px] max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{t('calendar.eventDetails.labels.eventDetails')}
						</h1>
						<div className="md:hidden" />
						<XMarkIcon
							onClick={() => setSelectedEvent(undefined)}
							className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<div className="p-5">
						<EventDetail
							{...selectedEvent}
							openNotePopup={openNotePopup}
							setOpenNotePopup={setOpenNotePopup}
							openAppointmentPopup={openAppointmentPopup}
							setOpenAppointmentPopup={(value: boolean) => {
								setOpenAppointmentPopup(value)
								setIsComponentVisible(true)
							}}
							refetch={() => {
								setSelectedEvent(undefined)
								refetch()
							}}
							onAppointmentClick={(id: string) => {
								setIsComponentVisible(false)
								onAppointmentClick(id)
							}}
							onEventClick={(id: string) => {
								setIsComponentVisible(false)
								onEventClick(id)
							}}
						/>
					</div>
				</Modal>
			)}
			{isComponentVisible && !isMobile && (
				<div className="fixed inset-0 bg-[#00000099] transition-opacity z-[99]" />
			)}
			<div className="sticky z-20 top-[113px] md:top-[81px] grid grid-cols-7 rounded-lg gap-px border border-[#F5F7FA] bg-[#E5EAF0] text-center text-xs leading-6">
				{(getAppLang() === Language.DE ? germanDaysList : daysList).map(day => (
					<div
						style={{ boxShadow: '0px 4px 24px 0xpx #1232581A' }}
						key={day}
						className=" text-black px-3 py-2">
						{day}
					</div>
				))}
			</div>
			<div
				style={{ boxShadow: '0px 4px 24px 0px #1232581A' }}
				className="grid grid-cols-7 gap-px bg-gray-200 rounded-xl border border-[#CFD8DC80]">
				{days.map((day, index) => {
					return (
						<DailySlot
							key={index}
							events={events}
							day={day}
							currentDate={currentDate}
							handleEventSelection={handleEventSelection}
							index={index}
							onDateClick={onDateClick}
							daysLength={days.length}
							onUpdate={onUpdate}
							isDragging={isDragging}
							setIsDragging={setIsDragging}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default MonthCalendar
