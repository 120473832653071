import axios from 'services/axios'

const getDestinationTypes = (): Promise<DestinationType[]> => {
	return axios.get('/company-destinationTypes').then(response => response.data)
}

const getDestinationTypeById = (typeId: string): Promise<DestinationType> => {
	return axios.get(`/destinationTypes/${typeId}`).then(response => response.data)
}

const createDestinationType = (data: DestinationTypeForm) => {
	return axios.post('/destinationTypes', data).then(response => response.data)
}

const updateDestinationType = (typeId: string, data: DestinationTypeForm) => {
	return axios.put(`/destinationTypes/${typeId}`, data).then(response => response.data)
}

const deleteDestinationType = (typeId: string) => {
	return axios.delete(`/destinationTypes/${typeId}`).then(response => response.data)
}

const destinationTypeService = {
	getDestinationTypes,
	getDestinationTypeById,
	createDestinationType,
	updateDestinationType,
	deleteDestinationType
}

export default destinationTypeService
