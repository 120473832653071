import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { DeletePrompt } from 'components/app/delete-prompt'
import { MenuColorPicker } from 'components/app/menu'
import { Input } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { mappedStatuses, Status } from 'constants/constants'
import destinationTypeService from 'services/category-service'
import { getTKey } from 'utils/language'

interface CreateCategoryProps {
	categoryId?: string
	onCancel: () => void
	onCreation: () => void
}

export const CreateCategory = ({ categoryId, onCancel, onCreation }: CreateCategoryProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('categories.create')
	const isEditForm = !!categoryId

	const [isLoading, setIsLoading] = useState(false)
	const [showDeletePrompt, setShowDeletePrompt] = useState(false)

	const schema = yup.object<DestinationTypeForm>().shape({
		name: yup.string().required(t(tKey('errors.categoryName'))),
		color: yup.string().required(t(tKey('errors.color'))),
		is_active: yup.string().required(t(tKey('errors.isActive')))
	})

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		reset,
		formState: { errors }
	} = useForm<DestinationTypeForm>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	useEffect(() => {
		if (categoryId) {
			destinationTypeService
				.getDestinationTypeById(categoryId)
				.then(res =>
					reset({ ...res, is_active: res.is_active ? Status.ACTIVE : (Status.INACTIVE as any) })
				)
		}
	}, [])

	const color = watch('color')

	const deleteCategory = () => {
		if (categoryId) {
			destinationTypeService
				.deleteDestinationType(categoryId)
				.then(() => {
					toast.success(t(tKey('toast.deleteSuccess')))
					onCreation()
				})
				.catch(() => toast.error(t(tKey('toast.deleteError'))))
		}
	}

	const onSubmit = (data: DestinationTypeForm) => {
		setIsLoading(true)
		if (isEditForm) {
			destinationTypeService
				.updateDestinationType(categoryId as string, {
					...data,
					is_active: (data.is_active as any) === Status.ACTIVE ? true : false
				})
				.then(() => {
					toast.success(t(tKey('toast.updateSuccess')))
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					t(tKey('toast.updateError'))
				})
				.finally(() => setIsLoading(false))
		} else {
			destinationTypeService
				.createDestinationType({
					...data,
					is_active: (data.is_active as any) === Status.ACTIVE ? true : false
				})
				.then(() => {
					toast.success(t(tKey('toast.categorySuccess')))
					onCreation()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.categoryError')))
				})
				.finally(() => setIsLoading(false))
		}
	}
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="md:px-8 py-6 max-md:px-5">
			{showDeletePrompt && (
				<DeletePrompt
					heading={t(tKey('titles.deleteCategory'))}
					message={t(tKey('labels.deleteMessage'))}
					onClose={() => setShowDeletePrompt(false)}
					onDelete={deleteCategory}
				/>
			)}
			<div className="flex flex-col gap-y-5">
				<p className="text-primary text-sm md:text-base">{t(tKey('titles.message'))}</p>
				<Input
					register={register}
					errors={errors}
					name="name"
					labelText={t(tKey('labels.categoryName'))}
				/>
				<Select
					name="is_active"
					labelText={t(tKey('labels.isActive'))}
					register={register}
					errors={errors}>
					{Object.values(mappedStatuses).map(status => (
						<option key={status} value={status.toLowerCase()}>
							{status}
						</option>
					))}
				</Select>
				<div className="flex flex-col gap-y-1 cursor-pointer">
					<MenuColorPicker
						color={color}
						setColor={(value: string) => setValue('color', value, { shouldValidate: true })}>
						<div className="relative flex items-center">
							<Input
								name="color"
								readOnly
								register={register}
								labelText={t(tKey('labels.colorIndicator'))}
							/>
							<div
								style={{ backgroundColor: color }}
								className="absolute left-3 h-4 w-4 -translate-y-px rounded"
							/>
						</div>
					</MenuColorPicker>
					{errors?.color && <p className="text-xs text-red-500">{errors.color.message}</p>}
				</div>
				<div className="flex gap-x-6 justify-end">
					<button
						type="button"
						onClick={() => (isEditForm ? setShowDeletePrompt(true) : onCancel())}
						className={clsx('font-bold text-sm', isEditForm ? 'text-[#F84C6B]' : 'text-primary')}>
						{isEditForm ? t(tKey('buttons.delete')) : t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{isEditForm ? t(tKey('buttons.update')) : t(tKey('buttons.add'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}
