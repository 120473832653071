import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { useAppSelector } from 'hooks'
import { Appointments } from 'pages/appointments'
import { AppointmentDetails } from 'pages/appointments/details'
import { BookingStatus } from 'pages/appointments/status'
import { Auth } from 'pages/auth'
import { Calendar } from 'pages/calendar'
import { ChangePassword } from 'pages/change-password'
import { Clients } from 'pages/clients'
import { ClientDetails } from 'pages/clients/details'
import { Dashboard } from 'pages/dashboard'
import { DestinationTypes } from 'pages/destination-types'
import { Destinations } from 'pages/destinations'
import { Drivers } from 'pages/drivers'
import { Invoices } from 'pages/invoices'
import { MobileSupport } from 'pages/support'

const Routing = () => {
	const auth = useAppSelector(state => state.auth)
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/auth"
					element={
						<PublicRoute auth={auth}>
							<Auth />
						</PublicRoute>
					}
				/>
				<Route
					path="/"
					element={
						<ProtectedRoute
							redirectPath="/calendar"
							customRedirect={auth.is_adminUser === false}
							auth={auth}>
							<Dashboard />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/calendar"
					element={
						<ProtectedRoute auth={auth}>
							<Calendar />
						</ProtectedRoute>
					}
				/>
				<Route path="/booking-confirmation/:id" element={<BookingStatus />} />
				<Route
					path="/bookings"
					element={
						<ProtectedRoute auth={auth}>
							<Appointments />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/destinations"
					element={
						<ProtectedRoute auth={auth}>
							<Destinations />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/booking-details/:id"
					element={
						<ProtectedRoute auth={auth}>
							<AppointmentDetails />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/clients"
					element={
						<ProtectedRoute auth={auth}>
							<Clients />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/invoices"
					element={
						<ProtectedRoute auth={auth}>
							<Invoices />
						</ProtectedRoute>
					}
				/>

				<Route
					path="/drivers"
					element={
						<ProtectedRoute
							redirectPath="/calendar"
							customRedirect={auth.is_adminUser === false}
							auth={auth}>
							{<Drivers />}
						</ProtectedRoute>
					}
				/>

				<Route
					path="/destination-type"
					element={
						<ProtectedRoute
							redirectPath="/calendar"
							customRedirect={auth.is_adminUser === false}
							auth={auth}>
							{<DestinationTypes />}
						</ProtectedRoute>
					}
				/>
				<Route
					path="/client-details/:id"
					element={
						<ProtectedRoute auth={auth}>
							<ClientDetails />
						</ProtectedRoute>
					}
				/>
				<Route
					path="/change-password"
					element={
						<ProtectedRoute auth={auth}>
							<ChangePassword />
						</ProtectedRoute>
					}
				/>

				<Route path="/support" element={<MobileSupport />} />
			</Routes>
		</BrowserRouter>
	)
}

type RouteProps = {
	auth?: AuthState
	redirectPath?: string
	children: JSX.Element
	customRedirect?: boolean
}

const ProtectedRoute = ({ auth, children, customRedirect, redirectPath = '/auth' }: RouteProps) => {
	if (!auth?.accessToken || customRedirect) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}

const PublicRoute = ({ auth, children, redirectPath = '/' }: RouteProps) => {
	if (auth?.accessToken) {
		return <Navigate to={redirectPath} replace={true} />
	}

	return children
}

export default Routing
