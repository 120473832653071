import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon, PencilIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'

import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import cond from 'cond-construct'
import { DateTime } from 'luxon'
import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Input } from 'components/inputs/input'
import { RadioInput } from 'components/inputs/radio'
import { ProgressBar } from 'components/progress-bar/progress-bar'
import { Language, germanToEnglishDays } from 'constants/constants'
import { useAppSelector } from 'hooks'
import companyService from 'services/company-service'
import driverService from 'services/driver-service'
import medicalService from 'services/medical-service'
import { getLocalizedWeekDays } from 'utils/date'
import { getAppLang, getTKey } from 'utils/language'

interface CreateDriverProps {
	id?: string
	onCancel: () => void
}

enum FormSteps {
	SERVICEDETAILS,
	SERVICETIME
}

export const CreateDriver = ({ id, onCancel }: CreateDriverProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('services.create')

	const isEditForm = !!id

	const auth = useAppSelector(state => state.auth)

	const [steps, setSteps] = useState(FormSteps.SERVICEDETAILS)
	const [driverId, setDriverId] = useState<string>()

	const renderComponent = cond([
		[
			steps === FormSteps.SERVICEDETAILS,
			() => (
				<DriverInfo
					id={id}
					setDriverId={setDriverId}
					onSubmission={() => setSteps(prev => prev + 1)}
					onCancel={onCancel}
				/>
			)
		],
		[
			steps === FormSteps.SERVICETIME,
			() => <DriverTime driverId={isEditForm ? id : (driverId as string)} />
		]
	])

	return (
		<div className="flex flex-col gap-y-5 px-5 md:px-8 py-6">
			<div className="mx-auto my-4 mb-8">
				<ProgressBar
					totalSteps={2}
					currentStep={steps}
					steps={[
						{ label: t(tKey('progressBar.driverDetails')), onAction: () => setSteps(0) },
						{
							label: t(tKey('progressBar.driverTime')),
							onAction: () => {
								if (!isEditForm) return
								setSteps(1)
							}
						}
					]}
				/>
			</div>
			<>{renderComponent}</>
		</div>
	)
}

interface DriverInfoProps {
	id?: string
	setDriverId: (value: string) => void
	onSubmission: () => void
	onCancel: () => void
}

const DriverInfo = ({ id, setDriverId, onSubmission, onCancel }: DriverInfoProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('clients.create')

	const [isLoading, setIsLoading] = useState(false)

	const schema = yup.object<DriverForm>().shape({
		fname: yup.string().required(t(tKey('errors.firstName'))),
		lname: yup.string().required(t(tKey('errors.lastName'))),
		gender: yup.string().required(t(tKey('errors.gender'))),
		email: yup
			.string()
			.required(t(tKey('errors.email')))
			.email(t(tKey('errors.validEmail'))),
		mobile: yup
			.string()
			.required(t(tKey('errors.phone')))
			.max(13, t(tKey('errors.maxPhone')))
			.min(11, t(tKey('errors.maxPhone'))),
		telephone: yup
			.string()
			.required(t(tKey('errors.phone')))
			.max(13, t(tKey('errors.maxPhone')))
			.min(11, t(tKey('errors.maxPhone'))),
		dob: yup
			.date()
			.typeError(t(tKey('errors.dob')))
			.max(new Date(Date.now() - 18 * 365 * 24 * 60 * 60 * 1000), t(tKey('errors.dobAge'))),
		vehicle: yup.string().required(t(tKey('errors.vehicle'))),
		specifications: yup.string().required(t(tKey('errors.specifications'))),
		address: yup.string().required(t(tKey('errors.address'))),
		city: yup.string().required(t(tKey('errors.city'))),
		zipcode: yup.string().required(t(tKey('errors.zip')))
	})

	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm<DriverForm>({
		resolver: yupResolver(schema as any),
		mode: 'all'
	})

	useEffect(() => {
		if (id) {
			driverService
				.getDriverById(id)
				.then(res =>
					reset({ ...res, dob: DateTime.fromMillis(Number(res.dob)).toFormat('yyyy-LL-dd') as any })
				)
		}
	}, [id])

	const onSubmit = (data: DriverForm) => {
		setIsLoading(true)

		if (id) {
			driverService
				.updateDriver(id, { ...data, dob: DateTime.fromJSDate(data.dob as any).toMillis() })
				.then(() => {
					toast.success(t(tKey('toast.updateSuccess')))
					onSubmission()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.updateError')))
				})
				.finally(() => setIsLoading(false))
		} else {
			driverService
				.createDriver({ ...data, dob: DateTime.fromJSDate(data.dob as any).toMillis() })
				.then(data => {
					setDriverId(data._id)
					toast.success(t(tKey('toast.addSuccess')))
					onSubmission()
				})
				.catch(error => {
					if (error?.response?.data?.message) {
						return toast.error(error?.response?.data?.message)
					}
					toast.error(t(tKey('toast.addError')))
				})
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="md:px-8 max-md:pt-8 md:py-6">
			<div className="flex flex-col gap-y-5">
				<div className="flex justify-between items-center">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.basicInfo'))}
					</h2>
				</div>
				<Controller
					control={control}
					name="gender"
					render={({ field: { onChange, value } }) => (
						<div className="flex flex-col">
							<div className="flex flex-row gap-x-3">
								<RadioInput
									labelText={t(tKey('labels.male'))}
									onChange={onChange}
									name="gender"
									register={register}
									value="male"
									checked={value === 'male'}
								/>
								<RadioInput
									labelText={t(tKey('labels.female'))}
									onChange={onChange}
									register={register}
									name="gender"
									value="female"
									checked={value === 'female'}
								/>
							</div>
							{errors?.gender && (
								<p className="text-xs text-red-500 mt-1">{errors.gender.message}</p>
							)}
						</div>
					)}
				/>
				<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
					<Input
						register={register}
						errors={errors}
						name="fname"
						labelText={t(tKey('labels.firstName'))}
					/>
					<Input
						register={register}
						errors={errors}
						name="lname"
						labelText={t(tKey('labels.lastName'))}
					/>
				</div>
				<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Input register={register} errors={errors} name="email" labelText="Email" />
					<Input
						name="vehicle"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.vehicle'))}
					/>
				</div>
				<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Input
						name="dob"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.dob'))}
						type="date"
					/>
					<Controller
						control={control}
						name={'mobile'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="name"
										className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.mobile'))}
									</label>
									<PhoneInput
										numberInputProps={{
											className:
												'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 focus:border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
										}}
										placeholder="Enter mobile number"
										defaultCountry="CH"
										value={value}
										error={error}
										onChange={onChange}
									/>
								</div>
								{errors?.mobile && (
									<p className="text-xs text-red-500 mt-1">{errors.mobile.message as string}</p>
								)}
							</div>
						)}
					/>
				</div>

				<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Controller
						control={control}
						name={'telephone'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<div className="relative overflow-visible rounded-md">
									<label
										htmlFor="name"
										className="absolute -top-2.5 text-xs cursor-text px-0.5 text-primary bg-white left-3">
										{t(tKey('labels.telephone'))}
									</label>
									<PhoneInput
										numberInputProps={{
											className:
												'w-full rounded font-normal pl-4 py-3 bg-white focus:ring-0 border-0 focus:border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
										}}
										placeholder="Enter telephone number"
										defaultCountry="CH"
										value={value}
										error={error}
										onChange={onChange}
									/>
								</div>
								{errors?.telephone && (
									<p className="text-xs text-red-500 mt-1">{errors.telephone.message as string}</p>
								)}
							</div>
						)}
					/>
					<Input
						name="specifications"
						register={register}
						errors={errors}
						labelText={t(tKey('labels.specifications'))}
					/>
				</div>

				<div className="flex flex-col gap-y-6 mt-5">
					<h2 className="text-primary font-domine font-bold text-sm md:text-lg">
						{t(tKey('titles.locationInfo'))}
					</h2>
					<div className="flex flex-col gap-y-5">
						<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.location'))}
								name="address"
							/>
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.zipCode'))}
								name="zipcode"
							/>
						</div>
						<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
							<Input
								register={register}
								errors={errors}
								labelText={t(tKey('labels.city'))}
								name="city"
							/>
						</div>
					</div>

					<div className="flex gap-x-6 justify-end">
						<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
							{t(tKey('buttons.cancel'))}
						</button>
						<Button type="submit" disabled={isLoading} className="text-sm font-bold">
							{isLoading ? (
								<div className="flex items-center justify-center gap-x-5">
									<Spinner />
									<span className="animate-pulse whitespace-nowrap">
										{t(tKey('buttons.pleaseWait'))}
									</span>
								</div>
							) : (
								<span>{!!id ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
							)}
						</Button>
					</div>
				</div>
			</div>
		</form>
	)
}

interface DriverTimeProps {
	driverId: string
}

const DriverTime = ({ driverId }: DriverTimeProps) => {
	const { t } = useTranslation()

	const tKey = getTKey('services.create')

	const [forceRefresh, setForceRefresh] = useState(false)
	const [time, setTime] = useState<{ [day: string]: ScheduleForm }>({})

	useEffect(() => {
		medicalService.getServiceSchedule(driverId).then(res => {
			const mappedTime = res
				.map(r => ({ ...r, day: r.day.toString() }))
				.reduce((agg, curr) => ({ ...agg, [curr.day]: curr }), {})
			setTime(mappedTime)
		})
	}, [forceRefresh])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, day: string) => {
		const { name, value } = event.target
		const germanToEnglish = germanToEnglishDays[day]

		const dateTime = DateTime.fromFormat(value, 'HH:mm')
		const weekDay =
			DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE').weekday ===
			7
				? 0
				: DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE').weekday

		if (!dateTime.isValid) {
			return setTime(prev => ({
				...prev,
				[weekDay.toString()]: {
					...prev[weekDay.toString()],
					day: weekDay.toString(),
					[name]: null
				}
			}))
		}
		setTime(prev => ({
			...prev,
			[weekDay.toString()]: {
				...prev[weekDay.toString()],
				day: weekDay.toString(),
				[name]: { hour: dateTime.hour, minute: dateTime.minute }
			}
		}))
	}

	const addSchedule = (weekDay: number) => {
		const payload: ScheduleForm = {
			start: time[weekDay]?.start ?? { hour: 0, minute: 0 },
			end: time[weekDay]?.end ?? { hour: 0, minute: 0 },
			pause_start: time[weekDay]?.pause_start ?? { hour: 0, minute: 0 },
			pause_end: time[weekDay]?.pause_end ?? { hour: 0, minute: 0 },
			id_driver: driverId,
			type: 'Custom',
			day: weekDay.toString()
		}
		medicalService
			.createServiceSchedule(payload)
			.then(() => {
				toast.success(t(tKey('toast.addScheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.addScheduleError'))))
	}

	const updateSchedule = (weekDay: number) => {
		const scheduleId = time[weekDay]._id as string
		delete time[weekDay].__v
		delete time[weekDay]._id
		delete time[weekDay].id_company
		companyService
			.updateCompanySchedule(scheduleId, time[weekDay])
			.then(() => {
				toast.success(t(tKey('toast.scheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.scheduleError'))))
	}

	const deleteSchedule = (weekDay: number) => {
		const scheduleId = (time[weekDay] as any)._id

		medicalService
			.deleteServiceSchedule(scheduleId)
			.then(() => {
				toast.success(t(tKey('toast.deleteScheduleSuccess')))
				setForceRefresh(prev => !prev)
			})
			.catch(() => toast.error(t(tKey('toast.deleteScheduleError'))))
	}

	return (
		<div className="md:px-8 max-md:pt-8 md:py-6">
			<div className="flex flex-col md:items-center md:gap-y-4">
				<table className="-mt-8 -mb-12 lg:overflow-x-hidden max-md:hidden table-auto w-full max-xl:block whitespace-nowrap overflow-x-auto border-separate border-spacing-x-[29px] border-spacing-y-[27px]">
					<thead>
						<tr>
							<th></th>

							<th className="text-primary text-center text-lg font-semibold">
								{t(tKey('tables.availability'))}
							</th>

							<th></th>
						</tr>
					</thead>
					<tbody>
						{getLocalizedWeekDays().map(day => {
							const germanToEnglish = germanToEnglishDays[day]

							const weekDay =
								DateTime.fromFormat(getAppLang() === Language.EN ? day : germanToEnglish, 'EEEE')
									.weekday === 7
									? 0
									: DateTime.fromFormat(
											getAppLang() === Language.EN ? day : germanToEnglish,
											'EEEE'
										).weekday
							return (
								<tr key={day}>
									<td className="px-2.5 bg-border rounded text-center text-primary font-semibold">
										{day}
									</td>

									<td>
										<div className="flex gap-x-5">
											<Input
												name="start"
												type="time"
												value={
													!time[weekDay.toString()]?.start ||
													time[weekDay.toString()]?.start === null
														? '00:00'
														: DateTime.fromObject(
																time[weekDay.toString()]?.start as Object
															).toFormat('HH:mm')
												}
												onChange={event => handleChange(event, day)}
											/>
											<Input
												name="end"
												value={
													!time[weekDay.toString()]?.end || time[weekDay.toString()]?.end === null
														? '00:00'
														: DateTime.fromObject(time[weekDay.toString()]?.end as Object).toFormat(
																'HH:mm'
															)
												}
												type="time"
												onChange={event => handleChange(event, day)}
											/>
										</div>
									</td>

									<td>
										{time[weekDay]?._id ? (
											<div className="flex gap-x-4">
												<PencilIcon
													onClick={() => updateSchedule(weekDay)}
													className="h-5 w-5 cursor-pointer text-primary"
												/>
											</div>
										) : (
											<Button onClick={() => addSchedule(weekDay)}>{t(tKey('buttons.add'))}</Button>
										)}
									</td>
								</tr>
							)
						})}
						<tr></tr>
					</tbody>
				</table>
				<div className="md:hidden">
					{getLocalizedWeekDays().map((day, index) => {
						const weekDay =
							DateTime.fromFormat(day, 'EEEE').weekday === 7
								? 0
								: DateTime.fromFormat(day, 'EEEE').weekday
						return (
							<Disclosure key={day}>
								{({ open }) => (
									<>
										<Disclosure.Button
											className={clsx(
												'flex justify-between w-full px-3 py-2 border-b border-border',
												{
													'bg-background': open,
													'border-b-0': index === getLocalizedWeekDays().length - 1
												}
											)}>
											<div className="flex justify-between gap-x-3 w-full">
												<div className="flex grow w-full gap-x-3 items-center">
													<ChevronRightIcon
														className={clsx('h-4 w-4 text-primary shrink-0', {
															'rotate-90 transform': open
														})}
													/>
													<p className="text-primary text-sm">{day}</p>
												</div>
											</div>
										</Disclosure.Button>
										<Disclosure.Panel>
											<div className="flex px-3 py-2 flex-col gap-y-1.5 border-b border-border bg-background">
												<h3 className="text-primary text-sm font-semibold">
													{t(tKey('tables.availability'))}
												</h3>
												<div className="flex justify-center gap-x-5 sm:grid sm:grid-cols-2">
													<input
														name="start"
														type="time"
														value={
															!time[weekDay.toString()]?.start ||
															time[weekDay.toString()]?.start === null
																? '00:00'
																: DateTime.fromObject(
																		time[weekDay.toString()]?.start as Object
																	).toFormat('HH:mm')
														}
														onChange={event => handleChange(event, day)}
														className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
													/>
													<input
														name="end"
														value={
															!time[weekDay.toString()]?.end ||
															time[weekDay.toString()]?.end === null
																? '00:00'
																: DateTime.fromObject(
																		time[weekDay.toString()]?.end as Object
																	).toFormat('HH:mm')
														}
														type="time"
														onChange={event => handleChange(event, day)}
														className="px-3 text-sm rounded focus:outline-none focus:ring-0 disabled:text-gray-500 focus:border-secondary border-[#D3E3F1] text-primary placeholder-[#7F9AB2]"
													/>
												</div>
											</div>

											{time[weekDay]?._id ? (
												<div className="bg-[#D3E3F1B2] text-primary grid auto-cols-auto grid-flow-col divide-x divide-gray-400 py-2 px-3 border-b border-border">
													<button
														className="cursor-pointer"
														onClick={() => updateSchedule(weekDay)}>
														{t(tKey('buttons.add'))}
													</button>
												</div>
											) : (
												<button
													onClick={() => addSchedule(weekDay)}
													className="bg-[#D3E3F1B2] w-full text-center text-primary py-2 px-3 border-b border-border">
													{t(tKey('buttons.add'))}
												</button>
											)}
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						)
					})}
				</div>
			</div>
		</div>
	)
}
