import { XMarkIcon } from '@heroicons/react/24/outline'
import { CreateAppointment } from 'pages/appointments/create'
import { useTranslation } from 'react-i18next'
import { getTKey } from 'utils/language'

interface CreateAppointmentFormProps {
	appointmentId: string | undefined
	onClose: () => void
	onAddClient: () => void
	onCreation: () => void
}

export const CreateOrUpdateAppointmentForm = ({
	appointmentId,
	onClose,
	onAddClient,
	onCreation
}: CreateAppointmentFormProps) => {
	const { t } = useTranslation()
	const tKey = getTKey('calendar.createEvent')

	return (
		<div className="flex overflow-auto md:fixed md:inset-y-0 md:right-0 md:z-[999] bg-white md:min-h-screen md:w-[360px] flex-col gap-y-3">
			<div className="flex max-md:hidden h-11 justify-between items-center py-1.5 px-4">
				<h1 className="font-domine font-bold leading-6 text-[#1C1C1C]">
					{t(tKey('headings.addAppointment'))}
				</h1>
				<XMarkIcon onClick={onClose} className="h-5 w-5 cursor-pointer text-[#1C1C1C]" />
			</div>
			<CreateAppointment
				showInfo={false}
				className="!p-5 !pt-3"
				onAddClient={onAddClient}
				onConfirm={onCreation}
				onCancel={onClose}
			/>
		</div>
	)
}
