import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { toast } from 'react-toastify'

import * as yup from 'yup'

import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { Input } from 'components/inputs/input'
import { Select } from 'components/inputs/select'
import { Status } from 'constants/constants'
import { useAppSelector } from 'hooks'
import { useNavigate } from 'react-router-dom'
import destinationTypeService from 'services/category-service'
import destinationService from 'services/destination-service'
import { getTKey } from 'utils/language'

interface CreateDestinationProps {
	destinationId?: string
	onCreation: () => void
	onCancel: () => void
}

export const CreateDestination = ({
	destinationId,
	onCreation,
	onCancel
}: CreateDestinationProps) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const tKey = getTKey('destinations.create')

	const auth = useAppSelector(state => state.auth)
	const isEditForm = !!destinationId

	const [isLoading, setIsLoading] = useState(false)
	const [destinationTypes, setDestinationTypes] = useState<DestinationType[]>([])

	const schema = yup.object<DestinationForm>().shape({
		destination_name: yup.string().required(t(tKey('errors.destinationName'))),
		cost: yup
			.number()
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.min(1, t(tKey('errors.minCost')))
			.required(t(tKey('errors.cost')))
			.typeError(t(tKey('errors.validCost'))),
		distance: yup
			.number()
			.transform((value, originalValue) => {
				return originalValue === '' ? undefined : value
			})
			.min(1, t(tKey('errors.minDistance')))
			.required(t(tKey('errors.distance')))
			.typeError(t(tKey('errors.validDistance'))),
		travel_time: yup.string().required(t(tKey('errors.travelTime'))),
		telephone: yup
			.string()
			.required(t(tKey('errors.phone')))
			.max(13, t(tKey('errors.maxPhone'))),
		type: yup.string().required(t(tKey('errors.type'))),
		address: yup.string().required(t(tKey('errors.address'))),
		city: yup.string().required(t(tKey('errors.city'))),
		zip_code: yup.string().required(t(tKey('errors.zip'))),
		is_active: yup.string().required(t(tKey('errors.status')))
	})

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		control
	} = useForm<DestinationForm>({
		resolver: yupResolver(schema as any),
		defaultValues: {
			telephone: '+41'
		},
		mode: 'all'
	})

	useEffect(() => {
		destinationTypeService.getDestinationTypes().then(response => setDestinationTypes(response))
	}, [auth, isEditForm])

	useEffect(() => {
		if (isEditForm) {
			destinationService.getDestinationById(destinationId).then(res => {
				reset({ ...res, is_active: res.is_active ? Status.ACTIVE : (Status.INACTIVE as any) })
			})
		}
	}, [isEditForm])

	const onSubmit = (data: any) => {
		setIsLoading(true)
		const payload: DestinationForm = {
			...data,
			id_company: auth.companyId,
			is_active: data.is_active === Status.ACTIVE ? true : false
		}
		if (isEditForm) {
			destinationService
				.updateDestination(destinationId as string, payload)
				.then(() => {
					toast.success(t(tKey('toast.updateSuccess')))
					onCreation()
				})
				.catch(() => toast.error(t(tKey('toast.updateError'))))
				.finally(() => setIsLoading(false))
		} else {
			destinationService
				.createDestination(payload)
				.then(() => {
					toast.success(t(tKey('toast.employeeSuccess')))
					onCreation()
				})
				.catch(err => toast.error(err?.response?.data?.message ?? t(tKey('toast.employeeError'))))
				.finally(() => setIsLoading(false))
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="md:px-8 px-5 py-6">
			<div className="flex flex-col gap-y-5">
				<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
					<Input
						register={register}
						errors={errors}
						name="destination_name"
						labelText={t(tKey('labels.destinationName'))}
					/>
					<Input
						register={register}
						errors={errors}
						name="distance"
						type="number"
						inputMode="numeric"
						labelText={t(tKey('labels.distance'))}
					/>
				</div>

				<div className="grid grid-cols-1 items-start md:grid-cols-2 gap-y-5 md:grid-flow-col gap-x-5">
					<Input
						register={register}
						errors={errors}
						name="cost"
						type="number"
						inputMode="numeric"
						labelText={t(tKey('labels.cost'))}
					/>
					<Select
						labelText={t(tKey('labels.type'))}
						name="type"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('placeholders.type'))}</option>
						{destinationTypes.map(type => (
							<option key={type._id} value={type._id}>
								{type.name}
							</option>
						))}
					</Select>
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
					<Controller
						control={control}
						name={'telephone'}
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<div className="flex flex-col">
								<PhoneInput
									numberInputProps={{
										className:
											'w-full rounded font-normal pl-4 py-3 bg-white focus:border-0 focus:ring-0 border-0 text-primary placeholder-[#7F9AB2] placeholder:text-base focus:outline-none text-md'
									}}
									placeholder="Enter phone number"
									defaultCountry="CH"
									value={value}
									error={error}
									onChange={onChange}
								/>
								{errors?.telephone && (
									<p className="text-xs text-red-500 mt-1">{errors.telephone.message as string}</p>
								)}
							</div>
						)}
					/>
					<Input
						register={register}
						errors={errors}
						name="travel_time"
						labelText={t(tKey('labels.travelTime'))}
					/>
				</div>
			</div>

			<div className="flex flex-col gap-y-5 mt-5">
				<div className="flex flex-col gap-y-5 md:flex-row gap-x-5">
					<Input
						register={register}
						errors={errors}
						labelText={t(tKey('labels.address'))}
						name="address"
					/>
					<Input
						register={register}
						errors={errors}
						labelText={t(tKey('labels.city'))}
						name="city"
					/>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-5">
					<Input
						register={register}
						errors={errors}
						labelText={t(tKey('labels.zipCode'))}
						name="zip_code"
					/>
					<Select
						labelText={t(tKey('labels.status'))}
						name="is_active"
						register={register}
						errors={errors}>
						<option value="">{t(tKey('labels.status'))}</option>
						<option value="aktiv">{t(tKey('labels.active'))}</option>
						<option value="inaktiv">{t(tKey('labels.inActive'))}</option>
					</Select>
				</div>
				<div className="flex gap-x-6 justify-end">
					<button type="button" onClick={onCancel} className="text-primary font-bold text-sm">
						{t(tKey('buttons.cancel'))}
					</button>
					<Button disabled={isLoading} className="text-sm font-bold">
						{isLoading ? (
							<div className="flex items-center justify-center gap-x-5">
								<Spinner />
								<span className="animate-pulse whitespace-nowrap">
									{t(tKey('buttons.pleaseWait'))}
								</span>
							</div>
						) : (
							<span>{isEditForm ? t(tKey('buttons.update')) : t(tKey('buttons.save'))}</span>
						)}
					</Button>
				</div>
			</div>
		</form>
	)
}
