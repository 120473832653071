import axios from 'services/axios'

const getActiveDestinations = (): Promise<Destination[]> => {
	return axios
		.get('/destinations?id_company=65bdf094838ad11df5784680')
		.then(response => response.data)
}

const getCompanyDestinations = (): Promise<Destination[]> => {
	return axios.get('/company/destinations').then(response => response.data)
}

const getDestinationById = (destinationId: string): Promise<Destination> => {
	return axios.get(`/destinations/${destinationId}`).then(response => response.data)
}

const createDestination = (data: DestinationForm) => {
	return axios.post('/destinations', data).then(response => response.data)
}

const updateDestination = (destinationId: string, data: DestinationForm) => {
	return axios.put(`/destinations/${destinationId}`, data).then(response => response.data)
}

const deleteDestination = (destinationId: string) => {
	return axios.delete(`/destinations/${destinationId}`).then(response => response.data)
}

const destinationService = {
	getActiveDestinations,
	getCompanyDestinations,
	getDestinationById,
	createDestination,
	updateDestination,
	deleteDestination
}

export default destinationService
