import axios from 'services/axios'

const getInvoices = (): Promise<Invoice[]> => {
	return axios.get('/company-invoices').then(response => response.data)
}

const getInvoiceById = (invoiceId: string): Promise<Invoice> => {
	return axios.get(`/invoices/${invoiceId}`).then(response => response.data)
}

const createInvoice = (data: Invoice) => {
	return axios.post(`/invoices/`, data).then(response => response.data)
}

const updateInvoice = (invoiceId: string, data: Invoice) => {
	return axios.put(`/invoices/${invoiceId}`, data).then(response => response.data)
}

const getInvoiceStatsByTime = (from?: number, to?: number): Promise<InvoiceStat> => {
	const params = new URLSearchParams()

	if (from !== undefined) {
		params.append('from', from.toString())
	}
	if (to !== undefined) {
		params.append('to', to.toString())
	}

	const queryString = params.toString()
	const url = queryString ? `/invoices-stats?${queryString}` : '/invoices-stats'

	return axios.get(url).then(response => response.data)
}

const invoiceService = {
	getInvoices,
	getInvoiceById,
	createInvoice,
	getInvoiceStatsByTime,
	updateInvoice
}

export default invoiceService
