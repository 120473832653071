import React, { useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'

import { CalendarEvent } from 'components/calendar'
import { useAppSelector } from 'hooks'
import appointmentService from 'services/appointment-service'
import eventService from 'services/event-service'

interface TimeBasedEventCardProps {
	event: CalendarEvent
	handleEventSelection: any
	groupLength: number
	subIndex: number
	currentDate: DateTime
	onUpdate: () => void
	isDragging: boolean
	setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
}

const TimeBasedEventCard = ({
	event,
	groupLength,
	subIndex,
	currentDate,
	handleEventSelection,
	isDragging,
	onUpdate,
	setIsDragging
}: TimeBasedEventCardProps) => {
	const auth = useAppSelector(state => state.auth)
	const [isResizing, setIsResizing] = useState(false)

	const eventStart = DateTime.fromISO(event.startDateTime)
	const eventEnd = DateTime.fromISO(event.endDateTime)
	const startMinutes = eventStart.hour * 60 + eventStart.minute
	const endMinutes = eventEnd.hour * 60 + eventEnd.minute

	const initialHeight = ((endMinutes - startMinutes) / 1440) * 100
	const [height, setHeight] = useState(initialHeight)

	const width = 100 / groupLength
	const left = subIndex * width + '%'

	const hourOffsetReduction = Math.floor(eventStart.hour / 2) * 0.15

	const baseOffset = 1.7
	const finalOffset = baseOffset - hourOffsetReduction
	const offset =
		eventStart.hour < 21
			? `calc(${(startMinutes / 1440) * 100}% + ${finalOffset}rem)`
			: `${(startMinutes / 1440) * 100}%`

	const [{ isCurrentItemDragging }, dragRef] = useDrag(
		() => ({
			type: 'daily',
			item: { totalMinutes: endMinutes - startMinutes, ...event },
			canDrag: () =>
				!isResizing &&
				(event.type === 'appointment' ||
					(event.type === 'event' && (event as any).addedBy._id === auth._id)),
			isDragging: monitor => {
				setIsDragging(true)
				return monitor.getItem().id === event.id
			},
			collect: monitor => ({
				isCurrentItemDragging: monitor.isDragging()
			}),
			end: () => setIsDragging(false)
		}),
		[]
	)

	const resizerRef = useRef<HTMLDivElement>(null)

	const onMouseOrTouchDown = (e: React.MouseEvent | React.TouchEvent, event: CalendarEvent) => {
		if (event.type === 'task') return
		e.stopPropagation()
		setIsResizing(true)

		const startY = 'touches' in e ? e.touches[0].clientY : e.clientY
		const startTime = DateTime.fromISO(event.startDateTime)
		const endTime = DateTime.fromISO(event.endDateTime)
		let updatedEndTime = endTime // Initialize the variable to store the updated end time

		const startHeight = resizerRef.current?.parentElement?.offsetHeight || 0

		const onMouseOrTouchMove = (e: MouseEvent | TouchEvent) => {
			const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY
			const heightChange = clientY - startY
			const newHeight = startHeight + heightChange
			if (
				resizerRef.current &&
				resizerRef.current.parentElement &&
				resizerRef.current.parentElement.parentElement
			) {
				const newHeightPercentage =
					(newHeight / resizerRef.current.parentElement.parentElement.offsetHeight) * 100

				setHeight(newHeightPercentage)
			}

			const minutesChange = (heightChange / 56) * 30
			updatedEndTime = endTime.plus({ minutes: minutesChange })

			if (updatedEndTime > endTime.endOf('day')) {
				updatedEndTime = endTime.set({ hour: 23, minute: 59 })
				const maxHeight = (updatedEndTime.diff(startTime, 'minutes').minutes / 60) * 64
				if (
					resizerRef.current &&
					resizerRef.current.parentElement &&
					resizerRef.current.parentElement.parentElement
				) {
					setHeight((maxHeight / resizerRef.current.parentElement.parentElement.offsetHeight) * 100)
				}
			}
		}

		const onMouseOrTouchUp = () => {
			setIsResizing(false)
			document.removeEventListener('mousemove', onMouseOrTouchMove)
			document.removeEventListener('mouseup', onMouseOrTouchUp)
			document.removeEventListener('touchmove', onMouseOrTouchMove)
			document.removeEventListener('touchend', onMouseOrTouchUp)

			if (event.type === 'appointment') {
				appointmentService
					.updateAppointmentSlots(event.id, startTime.toMillis(), updatedEndTime.toMillis())
					.then(res => {
						onUpdate()
					})
					.catch(err => {
						setHeight(initialHeight)
						toast.error(err?.response?.data?.message)
					})
			} else {
				eventService
					.updateEvent(event.id, {
						title: (event as any).title,
						description: event.description as string,
						duration: (event as any).duration,
						guests: (event as any).guests,
						allDay: event.allDay,
						colorCode: event.color,
						id_company: (event as any).companyId,
						from: startTime.toMillis(),
						to: updatedEndTime.toMillis()
					})
					.then(() => {
						onUpdate()
					})
					.catch(err => {
						setHeight(initialHeight)
						toast.error(err?.response?.data?.message)
					})
			}
		}

		document.addEventListener('mousemove', onMouseOrTouchMove)
		document.addEventListener('mouseup', onMouseOrTouchUp)
		document.addEventListener('touchmove', onMouseOrTouchMove)
		document.addEventListener('touchend', onMouseOrTouchUp)
	}

	return (
		<div
			onClick={() => handleEventSelection(event, currentDate.toISODate() as string)}
			className={clsx(
				'absolute cursor-pointer scroll-container gap-1 border-l-[1.2px] md:border-l-[3px] pl-2 pr-1 py-1.5 flex flex-col',
				isCurrentItemDragging || isDragging ? 'pointer-events-none' : 'pointer-events-auto',
				isDragging ? '!opacity-20' : ''
			)}
			style={{
				top: offset,
				height: `${height}%`,
				width: `calc(${width}% - 2px)`,
				left: `calc(${left} + 1px)`,
				boxSizing: 'border-box',
				background: event.color + '10',
				borderColor: event.color,
				zIndex: 10
			}}>
			<p className="text-primary font-semibold text-[10px] leading-[10px] md:text-sm md:leading-[18px]">
				{event.name}
			</p>
			<time
				dateTime={event.startDateTime}
				className="flex whitespace-nowrap items-center gap-[2.4px] md:gap-1.5 text-[#757575] text-[8px] md:text-xs">
				{DateTime.fromISO(event.startDateTime).toFormat('HH:mm')}
				<svg
					width="4"
					height="5"
					viewBox="0 0 4 5"
					fill="none"
					className="shrink-0"
					xmlns="http://www.w3.org/2000/svg">
					<circle cx="2" cy="2.55078" r="2" fill="#7F9AB2" />
				</svg>
				{event.type === 'appointment'
					? (event as any).id_client.fname + ' ' + (event as any).id_client.lname
					: (event as any).type === 'event'
						? (event as any).addedBy.fname + ' ' + (event as any).addedBy.lname
						: (event as any).assignedBy.fname + ' ' + (event as any).assignedBy.lname}
			</time>
		</div>
	)
}

export default TimeBasedEventCard
